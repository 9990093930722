import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { UserAvatarModule } from '@app/shared/user-avatar/user-avatar.module';
import { TranslateModule } from '@ngx-translate/core';
import { UserGroupSelectControlComponent } from './user-group-select-control.component';
import { UserGroupViewComponent } from './user-group-view/user-group-view.component';

@NgModule({
  declarations: [UserGroupSelectControlComponent, UserGroupViewComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, TranslateModule, UserAvatarModule],
  exports: [UserGroupSelectControlComponent],
})
export class UserGroupSelectControlModule {}
