<div class="flex flex-col space-y-2">
  <div class="mat-typography group relative flex h-10 select-none items-center space-x-3">
    <div class="mbg-card flex h-10 w-10 flex-col items-center justify-center rounded-full">
      <mat-icon class="!mx-0">group</mat-icon>
    </div>
    <div class="flex flex-1 flex-col space-y-0">
      <div>
        {{ group.useTranslate ? (group.name | translate) : group.name }}
      </div>
    </div>
    <div
      class="mat-bg-3-hover flex h-8 cursor-pointer flex-row items-center space-x-0.5 rounded px-2.5 text-sm"
      (click)="toggleUsersView()"
      *ngIf="showGroupUsers && group.users"
    >
      <ng-container *ngIf="group.users.length as userCount">
        <span>{{ userCount }} {{ 'workspace.users.name' | translate }}</span>
      </ng-container>
      <mat-icon inline class="!h-initial !leading-none" [ngClass]="{ '!rotate-180': showUsers.value }"
        >keyboard_arrow_down</mat-icon
      >
    </div>
    <ng-container
      *ngTemplateOutlet="
        showDelete ? deleteEntityTemplate : null;
        context: { entity: { entity: group, type: 'group', id: group.id } }
      "
    ></ng-container>
  </div>
  <div
    class="mat-bg-3 mb-2 flex flex-col gap-2 rounded px-1.5 py-2.5"
    *ngIf="showGroupUsers && showUsers.value"
    @matScale
  >
    <ng-container *ngFor="let user of group.users; trackBy: trackId">
      <ng-container *ngTemplateOutlet="groupUsersTemplate; context: { user: user }"></ng-container>
    </ng-container>
  </div>
</div>
